.preiframe{
    top: 0;
    left:0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 45px);
    position: relative;
    background-color: #000000;
}
.bottms{
    background-color: #034F4B;
    position:fixed;
    bottom:0;
}
.pimgs{
    padding-top: 10px;
    padding-bottom: 10px;
}
.pext{
    color: red;
    cursor: pointer;
}

.videoicon{
    color: #ffffff;
}

.controlsbarbutton{
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.controlsbartooltip {
    min-width:50px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.controlsbarbutton:hover .controlsbartooltip {
    visibility: visible;
    opacity: 1;
}
.greenselfthis{
    padding-top:12px;
    color: #72b5a4;
}
.modal-backdrop.show {
    opacity: 1 !important;
}
.pxsize{
    font-family: 'Brother 1816', sans-serif;
    font-size: 15px;
    color: #6e6e6e;
   }
.centerthisinput{
    font-family: 'Brother 1816', sans-serif;
    text-align: center;
    width:220px;
    height:35px;
    border:1px solid #5aab96;
    border-radius: 5px;
}