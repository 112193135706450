.fastaneditpage{
    width:90%;
    margin:20px auto;
    height:500px;
}
.singleradio{
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 3;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    width: 30px;
    height: 30px;
    position: absolute;
    margin-left:-40px;
}
.multicheckbox{
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 3;
    display: grid;
    width: 30px;
    height: 30px;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    position: absolute;
    margin-left:-40px;
}
.fillinthimgshow{
    width:400px;
}
@media screen and (max-width: 400px) {
    .fillinthimgshow{
        width:100%;
    }
}
.fastbuttoncolor{
    color: #ffffff;
    border-color: #DAF4A2;
    background-color: #034F4B !important;
    cursor: pointer;
    width:120px;
    padding:8px 16px
}
.peoplesview{
    position: absolute;
    margin-top:10px;
    margin-left:15px
}
.peopleviewscontainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Adjust the size as needed */
    gap: 10px; /* Spacing between items */
    justify-content: center;
    align-items: center;
}
.peopleviewsconitem{
    padding: 10px;
    text-align: center;
    border-radius: 5px;
}
.sizefortable{
    width:430px !important;
    margin:0 auto
}
.leftsitethis{
    text-align: left !important;
}