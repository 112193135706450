.peopleline{
    display: flex;
    align-items: baseline;
    margin: 0 auto;
    width:100px;
}
.peopleline img{
    margin-right: 10px;
    padding-top: 2px;
}
.peopleline p{
    font-size: 28px;
}
.thistable{
    width:580px !important;
    margin:10px auto;
    border:2px solid #000000 !important;
}