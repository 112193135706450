.imgshows{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100vh;
    background-color: black;
}
.imgviewsite{
    font-family: 'Brother 1816', sans-serif;
    max-width:1280px;
    height:auto;
    max-height: 100vh;

}
.gifresult{
    text-align: center;
}
.gitsearchexit{
    margin-right: 20px;
    margin-left: 10px;
}
.giphylogo{
    margin-left: 20px;
    margin-right: 10px;
}
@media only screen and (max-width: 1280px) {
    .imgviewsite{
        max-width:100%
    }
}
