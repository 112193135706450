.supportpage{
    margin:40px auto
}
.reachumuserguide-container {
    max-width: 1000px;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

/* Header styles */
.reachumuserguide-header {
    background-color: #034F4B;
    color: white;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 8px;
}
.reachumuserguide-safeask-feature {
    background-color: #eff6ff;
    border-left: 4px solid rgba(218, 244, 162, 0.7);
    padding: 16px;
    margin: 16px 0;
    border-radius: 4px;
}
.reachumuserguide-benefit-section {
    margin-top: 12px;
    border-top: 1px solid #e5e7eb;
    padding-top: 12px;
}
.reachumuserguide-header h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 8px;
}
.reachumuserguide-benefit-title {
    font-weight: 600;
    margin-bottom: 4px;
}

.reachumuserguide-benefit-list {
    font-size: 0.95rem;
    margin-left: 20px;
    margin-bottom: 8px;
}

/* SafeAsk in-depth section */
.reachumuserguide-safeask-indepth {
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 24px;
}

.reachumuserguide-safeask-indepth h3 {
    color: #096a53;
}
/* Section styles */
.reachumuserguide-section {
    margin-bottom: 32px;
    padding: 0 24px;
}

.reachumuserguide-section h2 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 16px;
    color: #034F4B;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 8px;
}
.reachumuserguide-section-accorder{
    padding:20px
}
.reachumuserguide-section h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 16px;
    color: #26967b;
}

.reachumuserguide-section h4 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
}
.reachumuserguide-section h5 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 8px;
}
.reachumuserguide-section h6 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 8px;
}
.reachumuserguide-green{
    color: #034F4B;
}
.reachumuserguide-underline{
    color: #26967b;
    text-decoration: underline;
}
.reachumuserguide-section p {
    margin-bottom: 16px;
}

.reachumuserguide-section ul,
.reachumuserguide-section ol {
    margin-left: 24px;
    margin-bottom: 16px;
}

.reachumuserguide-section li {
    margin-bottom: 8px;
}

.reachumuserguide-section strong {
    font-weight: bold;
}

/* Card styles */
.reachumuserguide-card {
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
}

.reachumuserguide-card-blue {
    background-color: rgba(239, 246, 255, 0.7);
}

.reachumuserguide-card-green {
    background-color: rgba(240, 250, 231, 0.62);
}

.reachumuserguide-card-yellow {
    background-color: #fffbeb;
}

.reachumuserguide-card-purple {
    background-color: rgba(245, 243, 255, 0.82);
}

.reachumuserguide-card-gray {
    background-color: rgba(243, 244, 246, 0.91);
}

.reachumuserguide-border-left-blue {
    border-left: 4px solid #2e63c5;
}

.reachumuserguide-border-left-yellow {
    border-left: 4px solid #E82C82;
}
.reachumuserguide-headerversion{
    margin-top: 12px;
    font-size: 0.9rem;
    opacity: 0.8;
}
/* Tip box */
.reachumuserguide-tip {
    background-color: rgba(218, 244, 162, 0.7);
    border-left: 4px solid #E82C82;
    padding: 16px;
    margin: 16px 0;
    border-radius: 4px;
}

.reachumuserguide-tip p {
    font-size: 0.875rem;
    color: #78123c;
    margin-bottom: 0;
}

/* Grid layout */
.reachumuserguide-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    margin-bottom: 24px;
}
.reachumuserguide-bottom-button{
    position: fixed;
    right: 23px; /* equivalent to right-6 in Tailwind */
    top: 94%;
    transform: translateY(-50%);
    background-color: #E82C82; /* blue-600 */
    color: white;
    padding: 12px; /* p-3 */
    border-radius: 9999px; /* rounded-full */
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    z-index: 50;
}

@media (min-width: 768px) {
    .reachumuserguide-grid-2 {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 768px) {
    .reachumuserguide-img{
        width:100%
    }
}
.thisfasttext{
    text-decoration: underline;
}
