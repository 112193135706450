.modalbackcolor{
    background-color: #9f9f9f !important;
}
.disiframe{
    width:100%;
    height:540px;
    border: 5px solid #000000;
    margin:0
}
.disroleplayiframe{
    width:100%;
    height:500px;
    border: 5px solid #01312e;
    margin:0
}
.roleplaycontent{
    border:2px solid #000000;
}
.underlinecustome{
    color: #5C6E68;
    text-decoration: underline;
}
