.timerpage{
    width:422px;
    height: 333px;
    background-color: #ffffff;
    border-radius: 6px;
    margin:85px auto;
    text-align: center;
}
.timerimg{
    width:422px;
    height:333px;
}

.timerg{
    padding-top: 50px;
    margin-left: -80px;
}
.labelinlinetimer{
    display: inline-block;
}
.biggerradiotimer{
    display: inline-block;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 2px solid #555;
    position: relative;
    margin-right: 5px;
    cursor: pointer;
}
.biggerradiotimer:checked + .biggerradiotimer::after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #555;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.smallform{
    width:70px;
    height:32px;
    margin-right: 10px;
    border:1px solid #258893;
}
.timersubmit{
    width:110px;
    background-color: #258893;
    color: #ffffff;
    padding: 5px 10px;
    border: 0;
    border-radius: 5px;
    margin-left: 35px;
}
.timersubmitw{
    width:70px;
    background-color: #258893;
    color: #ffffff;
    padding: 5px 10px;
    border: 0;
    border-radius: 5px;
}
.leftmagin{
    display:inline-block;
    margin-left: 30px;
}