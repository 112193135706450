.qrshows{
    margin-top:60px;
    text-align: center;
}
.qrshowsroom{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100vh;
}

