.answeriframe{
    font-family: 'Brother 1816', sans-serif;
    width:100%;
    height: 330px;
    border:2px solid #034F4B;
}
.thisimg{
    width:100%
}
.answerlist{
    font-family: 'Brother 1816', sans-serif;
    width:96%;
    margin:30px auto
}
.gofullwidth{
    width:98%;
    margin: 0 auto;
}
.quesname{
    font-family: 'Brother 1816', sans-serif;
    width: 100%;
    padding: 10px 15px;
    margin: 8px 0;
    box-sizing: border-box;
}
.quesname1{
    width: 60%;
    padding: 10px 15px;
    margin: 8px 0;
    box-sizing: border-box;
}
.answersubmit{
    font-family: 'Brother 1816', sans-serif;
    background-color: #034F4B !important;
    border-radius: 8px;
    color: #ffffff;
    padding: 8px 30px;
    cursor: pointer;
    border: none;
}
.answersroom{
    font-family: 'Brother 1816', sans-serif;
    width:100%;
    text-align: center;
}
.answersroomedit{
    font-family: 'Brother 1816', sans-serif;
    width:99%;
    margin:30px auto
}
.aiimgs{
    width:96%;
    height:auto;
    border:1px solid #f0f0f0;
}
.textareasanswer{
    font-family: 'Brother 1816', sans-serif;
    width:500px;
    height:150px;
    margin:8px auto
}
.convozeuploadimage{
    width:500px;
    padding:10px;
    margin:5px auto
}
.answershowleftside{
    text-align: right !important;
}
.listanswers{
    font-family: 'Brother 1816', sans-serif;
    display: inline-block;
    width:23%;
    height:220px;
    overflow: auto;
    text-align: center;
    border:2px solid #034F4B;
    padding:10px;
    border-radius: 10px;
    margin:5px;
    background-color: #ffffff;
}
.centerthisanswer{
    font-family: 'Brother 1816', sans-serif;
    width:500px;
    height:auto;
    margin: 10px auto;
    padding:8px 16px;
    text-align: center;
    border-radius: 10px;
    border:2px solid #034F4B;
    background-color: #f0f0f0;
}
@media screen and (max-width: 750px) {
    .listanswers{
        width:33%;
    }
}
.waitingforresponses{
    font-family: 'Brother 1816', sans-serif;
    font-size:72px;
    margin: auto;
    color:#9f9f9f;
}
@media screen and (max-width: 500px) {
    .textareasanswer{
        width:99%;
    }
    .centerthisanswer{
        width:99%
    }
    .convozeuploadimage{
        width:99%
    }
}

.answersshowroompage{
    width:100%;
    height: 100vh;
}
.answersspan{
    width:100%;
    padding:10px;
    cursor: pointer;
}
.anserrightside{
    width:98%;
    margin:10px auto
}
.answersroomwaiting{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:73px;
    color:lightgrey;
}
.wordcloudinput{
    border:1px solid #01312e;
    height:38px;
    width:200px;
}
.wordsleftside{
    text-align: left !important;
}
.rightsideredthis{
    text-align: right !important;
}
.addmorecontentbtn{
    background-color: #034F4B;
    padding:8px 16px;
    color: #ffffff;
    border-radius: 5px;
    border: none;
}
