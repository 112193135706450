.dragdroptimer{
    width:80px
}
.dragdropques{
    width:450px
}
.dragdroplist{
    padding-top: 10px;
}
.dragdropdele{
    padding-left: 10px;
}
.vl {
    border-left: 1px solid #0c4128;
    height: 100px;
    width:1px;
    margin:0 auto
}
.marginbottom{
    margin-bottom: 100px;
}
div.menu .menu-item.tile.tile-centered {
    margin-top: 5px;
}
.divider[data-content]::after, .divider-vert[data-content]::after{
    color:#000000 !important;
}
.divider{
    text-align: center;
}
.menu.hero-list {
    z-index: inherit;
    transform: none;
    min-height: 200px;
}
.dragdropform{
    margin-left: 10px;
    margin-right: 10px;
}
.dborder{
    border-radius: 5px;
    border: 1px solid #0c4128;
    padding:10px 15px;
    margin: 5px;
}
.ddcenter{
    font-family: 'Brother 1816', sans-serif;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}
.dragbottom{
    margin-top: 10px;
}
.tile-content {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Standard syntax */
}
.dragdroppage{
    font-family: 'Brother 1816', sans-serif;
    width:98%;
    margin:30px auto
}

.thisbottom{
    margin-bottom: 5px;
}
.newdragdroplist{
    padding:10px
}
.itemswidth{
    font-family: 'Brother 1816', sans-serif;
    border: 2px solid #034F4B;
    margin:5px;
    padding:10px 20px;
    width:31%;
    border-radius: 10px;
}
.tapflex-container {
    display: flex;
    flex-wrap: wrap;
    height:100vh;
    margin:0;
    padding-bottom:100px;
    font-family: 'Brother 1816', sans-serif;
}

.tapflex-item-left {
    padding: 10px;
    flex: 20%;
    background-color:#0c4128;
    height:100vh
}

.tapflex-item-right {
    flex: 80%;
}
.dragdropheadclock{
    width:90px;
    left:10%;
    top:40%;
    position: absolute;
    transform: translate(-50%, -50%);
    margin:auto;
    text-align:center;
    font-family: 'Brother 1816', sans-serif;
}

.dragdropitemsshow{
    font-family: 'Brother 1816', sans-serif;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    background-color: #ECECEC;
    margin: 10px auto;
    border-radius: 8px;
    width:98%
}
.catelists {
    font-family: 'Brother 1816', sans-serif;
    width: 32%;
    height: auto;
    background-color: #269b7b;
    color: white;
    padding: 8px;
    margin: 2px;
    border-radius:5px;
    text-align: center;
}
@media only screen and (max-width: 760px){
    .ddcenter{
        margin-top: 10px;
        margin-bottom: 0;
    }
    .tapflex-item-right, .tapflex-item-left {
        flex: 100%;
    }
    .tapflex-item-left {
        background-color:#ffffff;
        height:auto;
        margin-top:40px;
        padding-bottom:0
    }
    .dragdropheadclock{
        top:10px;
        position: relative;
    }
    .dragdropitems{
        flex:100%;
        flex-wrap: nowrap;
    }
}
.ReactCrop__child-wrapper>img{
    max-width:80% !important
}

.corrctcenterthistaptap{
    font-family: 'Brother 1816', sans-serif;
    width:99%;
    border: 1px solid #0c4128;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 15px;
    background-color: #ffffff;
    text-align: center;
}
.wraptaptap{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center
}
.taptaplist{
    font-family: 'Brother 1816', sans-serif;
    border: 2px solid #034F4B;
    margin: 5px;
    padding: 5px;
    width: 32%;
    border-radius:5px;
}
.taptaplistitem{
    font-family: 'Brother 1816', sans-serif;
    padding: 5px;
    flex: 1;
    margin:2px;
}
.greenthis{
    color:#0c4128;
}
.listtaptap{
    display:flex;
    flex-wrap: wrap
}

.seborderbottom{
    border-bottom: 1px solid #f0f0f0;
    padding: 10px;
}
.selist{
    border: 1px solid #0c4128;
    padding:10px;
    margin:5px;
    border-radius: 10px;
    font-size:22px
}
.centerthisse{
    font-family: 'Brother 1816', sans-serif;
    width:800px;
    margin:0 auto
}
@media only screen and (max-width: 800px){
    .centerthisse{
        width:98%;
    }
    .catelists {
        width: 47%
    }
}
@media only screen and (max-width: 700px){
    .catelists {
        width: 98%
    }
}
.rightsidese{
    padding-top: 10px;
    padding-right:10px;
    text-align: right;
}
.sebottom{
    margin-bottom: 80px;
}
.playsquencingborder{
    border:1px solid #0c4128;
}
@media only screen and (max-width: 540px){
    .playsquencingborder{
        width:98%;
        margin:0 auto
    }
}
.sefullwidth{
    width:100%;
    height: 40px;
}
.correctimages{
    width:250px
}
@media only screen and (max-width: 1200px){
    .taptaplist{
        width: 47%;
    }
}
@media only screen and (max-width: 760px){
    .taptaplist{
        width: 100%;
    }
}
.purple1back{
    font-family: 'Brother 1816', sans-serif;
    background-color: #570b3f;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    color: white;
    position: relative;
    width: 100%;
    height: auto;
}
.purpleback{
    font-family: 'Brother 1816', sans-serif;
    background-color: #a574dc;
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
    position: relative;
    width: 100%;
    height: auto;
}
.purplebutton{
    background-color: #311d48 !important;
    border-color: #311d48 !important;
}

.answersshowroomtext{
    font-family: 'Brother 1816', sans-serif;
    color:#ffffff
}
.rdropmenu button{
    font-family: 'Brother 1816', sans-serif;
    background-color: #311d48 !important;
    border-color: #311d48 !important;
}
.convosubmit{
    font-family: 'Brother 1816', sans-serif;
    background-color: #311d48 !important
}
.redbutton{
    font-family: 'Brother 1816', sans-serif;
    background-color: #E82C82 !important;
    border-color: #E82C82 !important;
    padding:3px 30px !important;
    font-size:21px !important
}

.biggertext{
    font-family: 'Brother 1816', sans-serif;
    font-size:20px
}
