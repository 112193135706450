.doughhutsize{
    width:96%;
    margin:0 auto
}
.engageheight{
    margin-top:40%;
    text-align: left !important;
}
.engageheightright{
    margin-top:30%;
    text-align: right !important;
}
.hoverreportmenu:hover{
    color:#26967b;
}
.sendreportform{
    border: 2px solid #01312e;
    width:200px;
    height:35px;
    padding:5px
}
.searchlessonlogform{
    border:2px solid #0c4128 !important;
    width:250px;
    height:35px;
    padding:5px;
    margin:6px;
    border-radius: 5px;
}
.searchbutton{
    border:none;
    height:35px;
    padding:5px 20px;
    margin:6px;
    border-radius: 5px;
    background-color: #0c4128;
    color: #ffffff;
}
.togglemenu{
    text-decoration: underline;
    color:#0c4128;
    cursor: pointer;
}
.togglemenu:hover{
    color:#046a65;
    cursor: pointer;
}
.leftlessonlogform{
    text-align: left !important;
    margin-bottom: -30px;
}
.redthissafe{
color: #E82C82;
}
.thisoverviewtable{
    width:580px;
    margin:10px auto
}