.editroom{
    font-family: 'Brother 1816', sans-serif;
    width:100%;
    margin:20px auto;
    padding: 10px;
    text-align: center;
}
.notes{
    text-align: left;
    margin-bottom: -15px;
    color:#E82C82;
}
.greens{
    font-family: 'Brother 1816', sans-serif;
    color:#034F4B
}
.movethis{
    cursor: all-scroll;
}
.firstadds{
    margin-left: -30px;
}

.lialist{
    font-family: 'Brother 1816', sans-serif;
    width:234px;
    height:192px;
    margin:5px;
    display: inline-block;
    border-radius: 5px;
    border:2px solid #aeadae;
}
.lialist .lialist{
margin-left: -1px;
    margin-top:-1px
}
.editinput{
    -webkit-appearance: none;
    border-width:0px;
    border:none;
}
.noi{
    position: absolute;
}
.editnoi{
    position: absolute;
    width: 229px;
    height: 130px
}
.editsframelook{
    width:229px;
    height:130px;
    overflow-y: hidden;
    overflow-x: hidden;
}
.editsframe{
    width:125px;
    height:72px;
    overflow: hidden !important;
    border-radius: 10px;
}
.edithead{
    text-align: center;
    margin-bottom: 10px;
}
.adds{
    text-align: center;
}
.bttons{
    margin-left: 20px;
}
.saves{
    width:99%;
    margin: 0 auto;
}

.golivebutton{
    width:130px;
    background-color: #26967b;
    color: #ffffff;
    padding: 4px 10px;
    border: 1px solid #26967b;
}
.input-container {
    position: relative;
}
.inputicon{
    position: absolute;
    top: 50%;
    right: 21%;
    transform: translateY(-50%);
}
.editroomnamebig{
    font-family: 'Brother 1816', sans-serif;
    font-size: 27px;
    font-weight: 500;
    color: #000000;
    border: none;
    text-align: center;
    width:60%;
    padding-right: 30px;
    border-bottom: 1px solid #daf4a2;
}
.savesheader{
    font-family: 'Brother 1816', sans-serif;
    width:99%;
    margin: 0 auto;
}
.topspace{
    margin-bottom: 10px;
}
.sharedropbtn{
    font-family: 'Brother 1816', sans-serif;
    padding:4px 10px;
    color: #000000;
    cursor: pointer;
}
.addsd{
    color: #174963;
    cursor: pointer;
}
.col-md-auto{
    margin-bottom: 10px;
}
.eext{
    width:150px;
    margin-left:30px
}
.editroomlist{
    width:70%;
    height: 24px;
    font-size: 14px;
    margin-left: 1px;
    margin-right: 1px;
    text-align: center;
    border:0
}

.characters {
    margin: 0;
    padding-bottom: 30px;
    color: #ffffff;
}
.editspan{
    font-family: 'Brother 1816', sans-serif;
    font-size: 14px;
    float: left;
    padding-top: 2px;
    padding-left: 2px;
}
.editcontents{
    font-family: 'Brother 1816', sans-serif;
    font-size: 22px;
    color: #000000;
}
.topedit{
    padding:2px
}
.imgslist{
    margin-top:0;
    width:229px;
    height: 133px;
    border-top:1px solid #000000;
    border-bottom:1px solid #000000;
}
.ccopys{
    font-family: 'Brother 1816', sans-serif;
    padding:10px;
}
.cccenter{
    font-family: 'Brother 1816', sans-serif;
    text-align: center;
}
.ccc{
    font-family: 'Brother 1816', sans-serif;
    margin-left: 6px;
    background-color: #DAF4A2;
    color: #034F4B;
    border: 1px solid #DAF4A2;
}
.inputsave {
    font-family: 'Brother 1816', sans-serif;
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid #DAF4A2;
    width:80px;
    height: 31px;
    background-color: #DAF4A2;
    color: #034F4B;
    border-radius: 5px;
    font-size: 18px;
}
.contents{
    font-family: 'Brother 1816', sans-serif;
    color: #034F4B;
}
#mySelect{
    font-family: 'Brother 1816', sans-serif;
    width: 160px;
    border: 1px solid #DAF4A2;
    color: #034F4B;
    background-color: #DAF4A2;
    font-size: 15px;
    margin-right: 3px;
    border-radius: 5px;
    font-weight: 500;
}
.dragable{
    width:10px;
}

.addse{
    text-align: right;
}
.upgradenew1{
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}
.ibuttonss{
    font-family: 'Brother 1816', sans-serif;
    width:90px;
    background-color: #DAF4A2;
    color:#034F4B
}
.tooltiptext1 {
    font-family: 'Brother 1816', sans-serif;
    min-width:150px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew1:hover .tooltiptext1 {
    visibility: visible;
    opacity: 1;
}
.upgradenew4{
    font-family: 'Brother 1816', sans-serif;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.tooltiptext4 {
    font-family: 'Brother 1816', sans-serif;
    min-width:120px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew4:hover .tooltiptext4 {
    visibility: visible;
    opacity: 1;
}
.upgradenew5{
    font-family: 'Brother 1816', sans-serif;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.tooltiptext5 {
    font-family: 'Brother 1816', sans-serif;
    min-width:250px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px;
    color:#000000;
    background-color:#e3e3e3;
    font-weight:normal;
    font-size:14px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border:1px solid #e3e3e3;box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.1s;
}

.upgradenew5:hover .tooltiptext5 {
    visibility: visible;
    opacity: 1;
}
.rehearsebutton {
    font-family: 'Brother 1816', sans-serif;
    width:130px;
    color: #077a76;
    padding: 3px 8px;
    border: 2px solid #077a76;
    background-color: #ffffff;
    margin-left:10px;
    margin-right: 10px;
}

.inputsavechanges{
    font-family: 'Brother 1816', sans-serif;
    width:140px;
    background-color: #d2196e;
    color: #ffffff;
    padding: 4px 10px;
    border: 1px solid #d2196e;
    margin-left: 8px;
}
.addqrbtn{
    font-family: 'Brother 1816', sans-serif;
    width:130px;
    background-color: #03706b;
    color: #ffffff;
    padding: 4px 10px;
    border: 1px solid #03706b;
}
.zooms{
    width:32px;
    height:32px;
    margin:0 auto
}
@media only screen and (max-width: 850px) {
    .editroom{
        max-width:100%;
    }
}
.saveseditchage{
    width:99%
}

.edititemsselected {
    border:5px solid #fab73d;
    transform: rotate(2deg);
}

.reachummoved {
    border:5px solid #fab73d;
    transform: rotate(2deg);
}
.fixed-divedit {
    position: fixed;
    bottom:2px;
    left: 25%;
    width: 50%;
    padding:10px;
    background-color: #ffffff;
    border-bottom:3px solid #fab73d;
    border-left:3px solid #fab73d;
    border-right:3px solid #fab73d;
    border-radius: 5px;
    opacity: 0.95;
    z-index: 1000; /* Adjust z-index as necessary */
}
.fixed-diveditsave {
    position: fixed;
    bottom:60px;
    left: 25%;
    width: 50%;
    padding:10px;
    background-color: #ffffff;
    border-top:3px solid #fab73d;
    border-left:3px solid #fab73d;
    border-right:3px solid #fab73d;
    border-radius: 5px;
    opacity: 0.95;
    z-index: 1000; /* Adjust z-index as necessary */
}

.menuslide{
    text-align: left !important;
    padding:5px 10px;
    cursor: pointer;
    font-family: 'Brother 1816', sans-serif;
}
.menuslides{
    padding-left:30px
}
.smallhr{
    margin-top:-8px
}
.movetextpanel{
    font-family: 'Brother 1816', sans-serif;
    width:170px;
    height:100px;
    font-size: 14px;
    border:1px solid #ffffff;
    background-color: #fab73d;
}
.centereditthis{
    width:160px;
    height:90px;
    padding:5px;
    margin:0 auto;
    overflow: hidden
}
.floatright{
    float:right;
    padding-top:6px;
    padding-right: 3px;
}
.bodyeditpage{
    margin-bottom: 100px;
}
.bottommenu{
    display: flex; /* Horizontal alignment for buttons */
    gap: 6px;
    padding: 10px;
}
.bottomeditmenu{
    position: fixed; /* Fix the position at the bottom of the viewport */
    bottom: 0; /* Stick it to the bottom */
    left: 0; /* Align to the left edge */
    width: 100%; /* Make it span the full width */
    display: flex; /* Enable flexbox layout */
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center .bottommenu */
    padding: 10px; /* Optional spacing */
    z-index: 1000; /* Ensure it stays above other content */
    background-color: #ffffff;
    border: 2px solid #6e6e6e;
}



.rightsidemenu{
    position: absolute; /* Position it independently */
    right: 25px; /* Align to the right side of the parent */
    top: 50%; /* Vertically center */
    transform: translateY(-50%); /* Correct vertical alignment */
    cursor: pointer;
}