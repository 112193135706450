.profiles{
    width:600px;
    margin: 30px auto;
    font-family: 'Brother 1816', sans-serif;
}
.shareemailbuton{
    width:160px;
    background-color: #096a53;
    color: #ffffff;
    padding: 6px 18px;
    border: 1px solid #096a53;
    margin:15px;
    border-radius: 6px;
}