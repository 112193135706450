.divcss{
    display: inline-block;
    width: 94%;
    text-align: left;
    padding:5px;
    border-radius: 1px;
    font-family: 'Brother 1816', sans-serif;
}

.center{
    text-align: center;
}

.selected {
    background-color: #a0dcbc;
    padding-top:2px;
    padding-bottom: 2px;
    font-family: 'Brother 1816', sans-serif;
}
.dasheditroomnamefoder{
    font-family: 'Brother 1816', sans-serif;
    font-size: 18px;
    color: #000000;
    text-align: center;
    width:99%;
    background-color: #ffffff;
    padding:5px;
    border: 1px solid #034F4B;
    margin-bottom: 10px;
}
.addfolderbutton{
    font-family: 'Brother 1816', sans-serif;
    width:90%;
    text-align: right;
}
.dashinputsavechanges{
    font-family: 'Brother 1816', sans-serif;
    width:350px;
    background-color: #72B5A4 !important;
    color: #ffffff;
    padding: 4px 6px;
    border: 1px solid #72B5A4 !important
}
.foldersavechanges{
    font-family: 'Brother 1816', sans-serif;
    padding:5px 15px !important;
    background-color: #595959 !important;
    color: #ffffff;
    border: 1px solid #595959 !important
}
.addsavechanges{
    font-family: 'Brother 1816', sans-serif;
    width:150px;
    background-color: #595959 !important;
    color: #ffffff;
    border: 1px solid #595959 !important
}
.newdashboardsave{
    font-family: 'Brother 1816', sans-serif;
    width:150px;
    background-color: #d2196e !important;
    border-color: #d2196e !important;
}
.dashvl {
    font-family: 'Brother 1816', sans-serif;
    border-left: 2px solid lightgray;
    height: 100vh;
    position: absolute;
    margin-top:-35px
}
.newroomlistpage{
    margin-left:20px
}
.dasheditroomnameinput{
    font-family: 'Brother 1816', sans-serif;
    font-size: 18px;
    color: #000000;
    border: none;
    text-align: left;
    width:90%;
    background-color: #F5F5F5;
    padding:5px;
    border-bottom: 1px solid #F5F5F5;
}
.dasheditroomnameinput1{
    font-family: 'Brother 1816', sans-serif;
    font-size: 18px;
    color: #000000;
    border: none;
    text-align: left;
    width:90%;
    background-color: #E9E9E9;
    padding:5px;
    border-bottom: 1px solid #e9e9e9;
}
.itemshrcss{
    margin: 0;
    opacity:0.1
}
.subfoldersviewcss{
    margin-top:15px
}
.middlemoveicons{
    padding-top:6px;
}
.editfoldernameicon{
    font-family: 'Brother 1816', sans-serif;
    position: absolute;
    margin-left:-35px;
    padding-top:6px
}
.betweenfolders{
    margin:0
}
.newcrroominput{
    font-family: 'Brother 1816', sans-serif;
    height:35px;
    width:400px !important;
    margin:10px auto;
}
h2{
    font-family: 'Brother 1816', sans-serif;
}
.hightlightroomlist:hover{
    background-color: #034F4B;
    color:#ffffff;
    padding: 3px 0px 3px 5px;
    cursor: pointer
}
.dragenter{
    text-align: center;
}
