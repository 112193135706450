.pdfpage{
    font-family: 'Brother 1816', sans-serif;
    margin:30px auto;
    text-align: center;
    width:100%;
    height:100%
}
.pdfiframe{
    font-family: 'Brother 1816', sans-serif;
    width:33%;
    height: 100% ;
    border:1px solid #000000;
    display: inline-block;
    padding:5px;
}
.pdfiframes{
    width:100%;
    min-height:700px;
}
.publiclessonpreview{
    width:33%;
    height:700px;
    position: absolute;
}
.publiclessonpreview1{
    width:100%;
    height:700px;
    position: absolute;
}

@media print {
    .pdfiframe {
        font-family: 'Brother 1816', sans-serif;
        position: relative;
        display: block; /* Ensure iframes are visible when printing */
        width: 100%;
        height: 100vh; /* Adjust height as needed */
        border: none;
        color:#000000
    }
    .publiclessonpreview{
        display:none
    }
}
