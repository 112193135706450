.calculator3{
    height:540px
}
.mathslidepage{
    width:99%;
    margin:10px auto
}
.pximage960{
    width:960px;
    }
.pximages400{
    width:400px;
    border-radius: 8px;
}
.pxsize500{
    width:500px;
}
.mathcccenter{
    text-align: center;
    margin-bottom: 5px;
}
.mathshowfont{
font-size: 24px;
}
@media only screen and (max-width: 400px) {
    .pximages400{
        width:100%
    }
}
@media only screen and (max-width: 500px) {
    .pxsize500{
        width:100%;
    }
}
@media only screen and (max-width: 960px) {
    .pximages960{
        width:100%
    }
}
