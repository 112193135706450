.correctanswerpage{
    background: url("answer-side-background.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
    width:100%;
    height:100vh;
    margin:0;
    position: relative;
    font-family: forma-djr-micro, sans-serif;
}
.corrctcenterthis{
    font-family: forma-djr-micro, sans-serif;
    width:70%;
    border: 1px solid;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 15px;
    background-color: #ffffff;
    text-align: center;
}
@media screen and (max-width: 800px) {
    .corrctcenterthis{
        width:98%;
    }
}
