.nav-items{
    font-family: 'Brother 1816', sans-serif;
    display: flex;
    justify-content: space-around;
}
#basic-nav-dropdown{
    color:#2bbece
}
.moli{
    display: none;
}
hr{
    margin-bottom: 0;
    margin-top: 0;
}
.logos{
    width:180px
}
.rightsidethis{
    text-align: right;
    margin-right:5px !important;
    margin-bottom: 15px;
}
.nav-link:hover{
    color:#E82C82 !important
}

.bg-body-tertiary{
    border-bottom: 2px solid Gray;
}
.navbar{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.redthissub{
    background-color: #5C6E68;
    padding:5px 18px;
    color: white;
    font-size:18px;
    border-radius: 6px;
}
.navthissub{
    font-size:16px;
    padding:5px 18px;
}
#basic-nav-dropdown{
    color: gray;
    margin-top: 5px;
    font-size:16px;
}
.navmenus{
    margin-top:5px;
    font-family: 'Brother 1816', sans-serif;
}
