.amplify-button[data-variation='primary'] {
    background-color: #d2196e;
    border-color: #d2196e;
    color: #ffffff;
}
.logincenter{
    margin-top:50px;
    text-align: center;
}
.loginsales{
    margin-top: 40px;
    text-align: center;
}

.redthisnote{
    color: #e82c2c;
    font-size: 15px;
}
