.muticontainer {
    font-family: 'Brother 1816', sans-serif;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.muticontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.multicheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border:1px solid #0c4128;
}

/* On mouse-over, add a grey background color */
.muticontainer:hover input ~ .multicheckmark {
    background-color: #daf4a2;
    border: 1px solid #0c4128;
}

/* When the checkbox is checked, add a blue background */
.muticontainer input:checked ~ .multicheckmark {
    background-color: #0c4128;
}

/* Create the multicheckmark/indicator (hidden when not checked) */
.multicheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

.muticontainer input:checked ~ .multicheckmark:after {
    display: block;
}

.muticontainer .multimulticheckmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.reachumcheckbox-container {
    margin: 10px;
    display: inline-block;
}

.reachumcheckbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top:15px;
    font-size:20px
}

.correctandincorrect{
    margin-right: 10px;
}
.nonheaderul{
    list-style-type:none;
}
.multicentermessage{
    text-align: center !important;
}
.ReactModal__Overlay{
    background-color: #000000 !important;
}
.sizeforbtable{
    width:560px !important;
    margin:0 auto
}