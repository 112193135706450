.fastviewleader{
    font-family: 'Brother 1816', sans-serif;
    width:90%;
    margin:20px auto;
    text-align: center;
}
.faviewleader{
    font-family: 'Brother 1816', sans-serif;
    width:100%;
    margin:0 auto;
    text-align: center;
    background-color: white;
    padding-bottom: 30px;
    border-radius: 2px;
    border: 2px solid #0c4128;
}
.leaderhead{
    font-family: 'Brother 1816', sans-serif;
    color: #0c4128;
}
.leadhead{
    font-family: 'Brother 1816', sans-serif;
    padding-top:20px;
    color: #0c4128;
}
.leaderpageview{
    font-family: 'Brother 1816', sans-serif;
    width:100%;
    height:100vh;
    background-color: #034F4B;
    background-image: url("leaderboard-background.jpg");
    background-repeat: repeat;
    padding-top:30px;
}
.moregames{
    font-family: 'Brother 1816', sans-serif;
    background-color: #72B5A4;
    color: #ffffff;
    padding: 4px 10px;
}
.morelreportgames{
    font-family: 'Brother 1816', sans-serif;
    background-color: #01312e;
    color: #ffffff;
    padding: 4px 30px;
    border: 1px solid #01312e;
}
.savescsv{
    font-family: 'Brother 1816', sans-serif;
    background-color: #72b5a4;
    color: #ffffff;
    padding: 4px 30px;
    border: 1px solid #72b5a4;
}
.moretreportgames{
    font-family: 'Brother 1816', sans-serif;
    background-color: #03706b;
    color: #ffffff;
    padding: 4px 12px;
    border: 1px solid #03706b;
}
.savespread{
    font-family: 'Brother 1816', sans-serif;
    background-color: #034F4B;
    color: #ffffff;
    padding: 4px 15px;
    border: 1px solid #034F4B;
    border-radius: 4px;
}
.savesnewpread{
    font-family: 'Brother 1816', sans-serif;
    background-color: #034F4B;
    color: #ffffff;
    padding: 4px 25px;
    border: none;
    border-radius: 4px;
}
.clearresub{
    font-family: 'Brother 1816', sans-serif;
    width:130px;
    background-color: #057e79;
    color: #ffffff;
    padding: 4px 10px;
    border: 1px solid #057e79;
}
.leadertable{
    font-family: 'Brother 1816', sans-serif;
    color: #0c4128;
}
@media only screen and (max-width: 600px) {
    .fastviewleader{
        width:100%
    }
}
@media only screen and (max-width: 550px) {
    .faviewleader{
        width:100%
    }
}
.viewerleaderpageview{
    font-family: 'Brother 1816', sans-serif;
    width:98%;
    margin:0 auto;
}
.morereportgames{
    font-family: 'Brother 1816', sans-serif;
    background-color: #72B5A4;
    color: #ffffff;
    padding: 5px 37px;
    border: 0;
}
.leaderviewleader{
    font-family: 'Brother 1816', sans-serif;
    width:100%;
    text-align: center;
    margin:0 auto
}
.reportpageheadview{
    font-family: 'Brother 1816', sans-serif;
    width:99%;
    margin:30px auto;
    text-align: center;
}
.thisreportstart{
    font-size: 40px;
    color: lightgrey;
    margin-top:80px;
}

.copys{
    cursor: pointer;
    font-family: 'Brother 1816', sans-serif;
    padding:5px 15px !important;
    background-color: #034F4B !important;
    color: #ffffff;
    border: 1px solid #034F4B !important
}
.lessonslist{
    padding:10px 20px;
    text-align: left !important;
}
.shortertext{
    height:60px !important;
    overflow: auto;
}