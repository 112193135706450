.viframe{
    top: 0;
    left:0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 45px);
    position: relative;
    background-color: #000000;
    overflow: hidden;
}
.mcviframe{
    top: 0;
    left:0;
    right: 0;
    position: relative;
    background-color: #F5F5F5;
}
.mcbviframe{
    top: 0;
    left:0;
    right: 0;
    position: relative;
    width: 100%;
    height: calc(100vh - 420px);
    background-color: #F5F5F5;
}
.popupiframe{
    top: 0;
    left:0;
    right: 0;
    width:100%;
    height: 100vh;
}
.endright{
    width:300px;
    margin-right: 0;
}
.viewbimg{
    padding-top: 10px;
    padding-bottom: 9px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}
.viewright{
    text-align: center;
}
.updatenameform{
    width:280px;
    height:33px;
    font-size: 13px;
    border-color: #daf4a2;
}
.thisfontsize{
    font-size: 14px;
}
.updateformbutton{
 width:100px;
    background-color: #034F4B;
    margin-right: 15px;
}
.spanspace{
    margin-right: 10px;
    margin-top:5px;
    font-size:13px;
    border: 1px solid #eae9e9;
    background-color: #e9e9e9;
    border-radius: 10px;
}
.craeteroomgame{
    width:250px;
    height:35px;
    border: 1px solid #daf4a2;
}
.whitethis{
    color: white;
    margin-top:10px
}
.underscored{
    text-decoration: underline;
    color: #E82C82;
}
.ibuttnsform{
    background-color: #269b7b !important;
}
.ibuttnsform:hover{
    background-color: #034f4b !important;
}
.enterfirstyournameform{
    width:400px;
    margin:20px auto;
    padding:10px;
    text-align: center;
}
@media only screen and (max-width: 400px) {
    .enterfirstyournameform{
        width:100%
    }
}

.teamsiframe{
    top: 0;
    left:0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #000000;
    overflow: hidden;
}