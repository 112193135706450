.previewinvitation{
    width:100%;
    height: 100vh;
    padding: 20px 30px;
    font-family: 'Brother 1816', sans-serif;
}
.inviteformpage{
    width:100%;
    margin:0 auto;
    padding: 20px 20px 50px;
    font-family: 'Brother 1816', sans-serif;
}

.thistimers{
    margin-left: 10px;
    width:50px
}
.invitforms{
    width:600px;
    margin: 20px auto;
    padding:20px;
    background-color: white;
    border-radius: 5px;
    font-family: 'Brother 1816', sans-serif;
}
.thisp{
    font-size: 90%;
    font-family: 'Brother 1816', sans-serif;
}
.thisidate{
    width:160px
}
.thosp{
    color: #e24761;
}
.inviteguestslist{
    width:90%;
    margin: 0px auto;
    padding:20px;
    background-color: white;
    border-radius: 5px;
}
.sendbutton{
    background-color: #0c4128;
    width:100px
}
.centerinvite{
    text-align: center;
    width:280px;
    margin:0 auto;
    background-color: #72B5A4;
    color: white;
    font-family: 'Brother 1816', sans-serif;
}
.h3text{
    padding: 10px 0 5px;
}
@media only screen and (max-width: 1000px) {
    .inviteformpage{
        width:99%;
    }
    .invitforms{
        width:99%
    }
}

.datetext{
    margin-bottom: -10px;
}
.staethis{
    margin-bottom: -20px;
}
.inviteformtext{
    margin-top: 8px;
}
.datepickerinput{
    width:130px !important
}
